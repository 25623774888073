import { preloadFonts } from './utils';
import { TypeShuffle } from './typeShuffle';

preloadFonts('biu0hfr').then(() => {
    document.body.classList.remove('loading');

    // const textElement = document.querySelector('.content');
    
    // const ts = new TypeShuffle(textElement);
    // ts.trigger('fx1');

    // spans = [...document.querySelectorAll('.content > dd')];

    // spans.forEach(dd => {
    //     dd.addEventListener('mouseenter', () => { // Pass the current dd element to TypeShuffle
    //         ts.trigger('fx6');
    //     });
    // });

const contents = ['content', 'content2', 'content3', 'content4', 'content5', 'content6', 'content7'];


// //reload
// contents.forEach(contentClass => {
//     const textElement = document.querySelector(`.${contentClass}`);
    
//     if (textElement) {
//         const ts = new TypeShuffle(textElement);
//         ts.trigger('fx1');
//     }
// });

// //mouseover

// contents.forEach(contentClass => {
//     initializeTypeShuffle(contentClass);
// });

// function initializeTypeShuffle(contentClass) {
//     const textElement = document.querySelector(`.${contentClass}`);
//     const ts = new TypeShuffle(textElement);
//     const spans = [...document.querySelectorAll(`.${contentClass} > dd`)];

//     spans.forEach(dd => {
//         dd.addEventListener('mouseenter', () => {
//             ts.trigger('fx6');
//         });
//     });
// }



contents.forEach(contentClass => {
    initializeTypeShuffle(contentClass);
});

function initializeTypeShuffle(contentClass) {
    const textElement = document.querySelector(`.${contentClass}`);
    
    if (textElement) {
        const ts = new TypeShuffle(textElement);
        ts.trigger('fx1'); // Trigger fx1 effect initially
        
        const spans = [...document.querySelectorAll(`.${contentClass} > dd`)];
        
        spans.forEach(dd => {
            dd.addEventListener('mouseenter', () => {
                ts.trigger('fx6'); // Trigger fx6 effect on mouse enter
            });
        });
    }
}

});